import actions from './actions';

const initState = {
    orders: [],
    newOrders: [],
    orderDetail: {},
    orderLines: [],
    orderLineDetail: {},
    orderStates: [],
    loading: false,
    loadingOrderLineDetail: false,
    totalItems: 0,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        //#region CRUD
        case actions.GET_ORDERS:
            return { ...state, loading: true, totalItems: 0 };
        case actions.GET_ORDERS_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, orders: res, totalItems: totalItems, loading: false };
        }

        case actions.GET_ORDER_DETAIL:
            return { ...state, orderDetail: {}, loading: true };
        case actions.GET_ORDER_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, orderDetail: res, loading: false };
        }

        case actions.GET_ORDER_STATES:
            return { ...state, loading: true };
        case actions.GET_ORDER_STATES_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, orderStates: res, loading: false };
        }
        //#endregion

        //#region ORDER LINE
        case actions.GET_ORDER_LINES:
            return { ...state, orderLines: [], loadingOrderLine: true, totalItems: 0 };
        case actions.GET_ORDER_LINES_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, orderLines: res, totalItems: totalItems, loadingOrderLine: false };
        }

        case actions.GET_ORDER_LINE_DETAIL:
            return { ...state,  orderLineDetail: {}, loadingOrderLineDetail: true };
        case actions.GET_ORDER_LINE_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, orderLineDetail: res, loadingOrderLineDetail: false };
        }

        case actions.GET_NEW_ORDERS:
            return { ...state, loading: true, newOrders: [] };
        case actions.GET_NEW_ORDERS_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : [];
            return { ...state, newOrders: res, loading: false };
        }
        //#endregion

        case actions.ORDER_LOADING_FALSE:
            return { ...state, loading: false, loadingOrderLineDetai: false };

        default:
            return state;

    }
}
