const actions = {

    GET_PRODUCT_TYPES: 'GET_PRODUCT_TYPES',
    GET_PRODUCT_TYPES_SUCCESS: 'GET_PRODUCT_TYPES_SUCCESS',

    GET_PRODUCT_TYPE_DETAIL: 'GET_PRODUCT_TYPE_DETAIL',
    GET_PRODUCT_TYPE_DETAIL_SUCCESS: 'GET_PRODUCT_TYPE_DETAIL_SUCCESS',

    CREATE_PRODUCT_TYPE: 'CREATE_PRODUCT_TYPE',
    UPDATE_PRODUCT_TYPE: 'UPDATE_PRODUCT_TYPE',
    DELETE_PRODUCT_TYPE: 'DELETE_PRODUCT_TYPE',

    PRODUCT_TYPE_LOADING_FALSE: 'PRODUCT_TYPE_LOADING_FALSE',
    //#region CRUD
    getProductTypes: model => ({
        type: actions.GET_PRODUCT_TYPES,
        payload: { model }
    }),
    deleteProductType: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_PRODUCT_TYPE,
        payload: { id },
        cbSuccess, cbError
    }),
    getProductTypeDetail: id => ({
        type: actions.GET_PRODUCT_TYPE_DETAIL,
        payload: { id }
    }),
    createProductType: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_PRODUCT_TYPE,
        payload: { model },
        cbSuccess, cbError
    }),
    updateProductType: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_PRODUCT_TYPE,
        payload: { model },
        cbSuccess, cbError
    }),
    //#endregion
}

export default actions;