const actions = {

    //#region CUSTOMER TYPE
    GET_CUSTOMER_TYPES: 'GET_CUSTOMER_TYPES',
    GET_CUSTOMER_TYPES_SUCCESS: 'GET_CUSTOMER_TYPES_SUCCESS',

    GET_CUSTOMER_TYPE_DETAIL: 'GET_CUSTOMER_TYPE_DETAIL',
    GET_CUSTOMER_TYPE_DETAIL_SUCCESS: 'GET_CUSTOMER_TYPE_DETAIL_SUCCESS',

    CREATE_CUSTOMER_TYPE: 'CREATE_CUSTOMER_TYPE',
    UPDATE_CUSTOMER_TYPE: 'UPDATE_CUSTOMER_TYPE',

    DELETE_CUSTOMER_TYPE: 'DELETE_CUSTOMER_TYPE',

    getCustomerTypes: model => ({
        type: actions.GET_CUSTOMER_TYPES,
        payload: { model }
    }),
    deleteCustomerType: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_CUSTOMER_TYPE,
        payload: { id },
        cbSuccess, cbError
    }),
    getCustomerTypeDetail: id => ({
        type: actions.GET_CUSTOMER_TYPE_DETAIL,
        payload: { id }
    }),
    createCustomerType: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_CUSTOMER_TYPE,
        payload: { model },
        cbSuccess, cbError
    }),
    updateCustomerType: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_CUSTOMER_TYPE,
        payload: { model },
        cbSuccess, cbError
    }),
    //#endregion

    //#region CUSTOMER
    GET_CUSTOMERS: 'GET_CUSTOMERS',
    GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',

    GET_CUSTOMER_DETAIL: 'GET_CUSTOMER_DETAIL',
    GET_CUSTOMER_DETAIL_SUCCESS: 'GET_CUSTOMER_DETAIL_SUCCESS',

    CUSTOMER_PURCHASE_ORDER: 'CUSTOMER_PURCHASE_ORDER',
    CUSTOMER_PURCHASE_ORDER_SUCCESS: 'CUSTOMER_PURCHASE_ORDER_SUCCESS',

    CREATE_CUSTOMER: 'CREATE_CUSTOMER',
    UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
    RESET_CUSTOMER_PASSWORD: 'RESET_CUSTOMER_PASSWORD',

    DELETE_CUSTOMER: 'DELETE_CUSTOMER',

    CUSTOMER_LOADING_FALSE: 'CUSTOMER_LOADING_FALSE',

    getCustomers: model => ({
        type: actions.GET_CUSTOMERS,
        payload: { model }
    }),
    deleteCustomer: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_CUSTOMER,
        payload: { id },
        cbSuccess, cbError
    }),
    getCustomerDetail: id => ({
        type: actions.GET_CUSTOMER_DETAIL,
        payload: { id }
    }),
    createCustomer: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_CUSTOMER,
        payload: { model },
        cbSuccess, cbError
    }),
    updateCustomer: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_CUSTOMER,
        payload: { model },
        cbSuccess, cbError
    }),
    resetCustomerPassword: (id, cbSuccess, cbError) => ({
        type: actions.RESET_CUSTOMER_PASSWORD,
        payload: { id },
        cbSuccess, cbError
    }),
    customerPurchaseOrder: (model, id) => ({
        type: actions.CUSTOMER_PURCHASE_ORDER,
        payload: { model, id }
    }),

    RESET_CUSTOMER_DETAIL: 'RESET_CUSTOMER_DETAIL',
    resetCustomerDetail: () => ({
        type: actions.RESET_CUSTOMER_DETAIL,
    }),
    //#endregion
}

export default actions;