import actions from './actions';

const initState = {
    inventoryVouchers: [],
    inventoryVoucherDetail: {},
    inventoryVoucherLines: [],
    loadingLines: false,
    loading: false,
    totalItems: 0,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        //#region CRUD TRANSFER VOUCHERS
        case actions.GET_TRANSFER_VOUCHERS:
            return { ...state, loading: true, inventoryVouchers: [], totalItems: 0 };
        case actions.GET_TRANSFER_VOUCHERS_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, inventoryVouchers: res, totalItems: totalItems, loading: false };
        }

        case actions.GET_TRANSFER_VOUCHER_DETAIL:
            return { ...state, inventoryVoucherDetail: {}, loading: true };
        case actions.GET_TRANSFER_VOUCHER_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, inventoryVoucherDetail: res, loading: false };
        }

        //#endregion

        //#region TRANSFER_VOUCHER LINE
        case actions.GET_TRANSFER_VOUCHER_LINES:
            return { ...state, inventoryVoucherLines: [], loadingLines: true, totalItems: 0 };
        case actions.GET_TRANSFER_VOUCHER_LINES_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, inventoryVoucherLines: res, totalItems: totalItems, loadingLines: false };
        }
        //#endregion

        //#region CRUD IMPORT VOUCHERS
        case actions.GET_IMPORT_VOUCHERS:
            return { ...state, loading: true, inventoryVouchers: [], totalItems: 0 };
        case actions.GET_IMPORT_VOUCHERS_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, inventoryVouchers: res, totalItems: totalItems, loading: false };
        }

        case actions.GET_IMPORT_VOUCHER_DETAIL:
            return { ...state, inventoryVoucherDetail: {}, loading: true };
        case actions.GET_IMPORT_VOUCHER_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, inventoryVoucherDetail: res, loading: false };
        }
        //#endregion

        //#region IMPORT_VOUCHER LINE
        case actions.GET_IMPORT_VOUCHER_LINES:
            return { ...state, inventoryVoucherLines: [], loadingLines: true, totalItems: 0 };
        case actions.GET_IMPORT_VOUCHER_LINES_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, inventoryVoucherLines: res, totalItems: totalItems, loadingLines: false };
        }
        //#endregion           

        case actions.INVENTORIE_VOUCHERS_LOADING_FALSE:
            return { ...state, loading: false, loadingLines: false };

        default:
            return state;

    }
}
