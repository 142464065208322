import axios from 'axios';
import { deleteToken } from '@iso/lib/helpers/localStorage';
import swal from 'sweetalert';

const BASE_API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:6880'

export const api = axios.create({
    baseURL: BASE_API_URL,
    timeout: 30000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});

export function axiosDownload(url, method, model = null, data = null) {
    return {
      baseURL: url,
      method: method,
      timeout: 0,
      responseType: "blob",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("USER_TOKEN") === null ? null : `Bearer ${localStorage.getItem("USER_TOKEN")}`,
      },
      params: model,
      data: data,
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); // you can use this to show user percentage of file downloaded
        console.log(percentCompleted);
      }
    };
  };

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const { response: { status, data } } = error;
        if (status === 401) {
            deleteToken();
            window.location.href = "/signin";
        }
        if (status === 403 && !data?.error?.code) {
            window.location.href = "/403";
        }
        if (status === 426) {
            swal({
                title: "Thông báo",
                text: "Có sự thay đổi về hệ thống, vui lòng đăng nhập lại.",
                icon: "info",
                buttons: [false, "Ok"],
            }).then((value) => {
                deleteToken();
                window.location.href = "/signin";
            });;
        }
        return Promise.reject(error);
    }
);

api.defaults.headers.common['Authorization'] = localStorage.getItem('USER_TOKEN') === null ? null : `Bearer ${localStorage.getItem('USER_TOKEN')}`;

export function setAuthorization(token) {
    api.defaults.headers.common['Authorization'] = token === null ? token : `Bearer ${token}`
}

export function removeAuthorization() { //for Logout
    setAuthorization(null)
}