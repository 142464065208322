import { all } from 'redux-saga/effects';
import authSaga from '@iso/redux/auth/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import accountSaga from '@iso/redux/account/saga';
import commonSaga from '@iso/redux/common/saga';
import userSaga from '@iso/redux/users/saga';
import roleSaga from '@iso/redux/roles/saga';
import productSaga from '@iso/redux/products/saga';
import productTypeSaga from '@iso/redux/productTypes/saga';
import blogSaga from '@iso/redux/blogs/saga';
import orderSaga from '@iso/redux/orders/saga';
import customerSaga from '@iso/redux/customers/saga';
import reportSaga from '@iso/redux/reports/saga';
import settingSaga from '@iso/redux/settings/saga';
import syncSaga from '@iso/redux/syncs/saga';
import inventorySaga from '@iso/redux/warehouses/inventories/saga';
import inventoryVoucherSaga from '@iso/redux/warehouses/inventoryVouchers/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    ecommerceSaga(),
    accountSaga(),
    commonSaga(),
    userSaga(),
    roleSaga(),
    productSaga(),
    productTypeSaga(),
    blogSaga(),
    orderSaga(),
    customerSaga(),
    reportSaga(),
    settingSaga(),
    syncSaga(),
    inventorySaga(),
    inventoryVoucherSaga(),
  ]);
}
