const baseRouter = "/api/app/";
const baseCommonRouter = "/api/commons/";

export const ApiRouters = {
    LOGIN: baseRouter + "auths/login",
    FORGOT_PASSWORD: baseRouter + "auths/forgot-password",
    CHECK_TOKEN_RESET_PW: baseRouter + "auths/check-reset-password",
    RESET_PASSWORD: baseRouter + "auths/reset-password",
    ACCOUNT: baseRouter + "accounts",

    USER_LOGIN_PROFILE: baseRouter + "accounts/my-profile",
    CHANGE_PASSWORD: baseRouter + "accounts/change-password",

    CUSTOMER: baseRouter + "customers",
    USER: baseRouter + "users",
    COMMON: "/api/commons",
    ROLE: baseRouter + "roles",
    PRODUCT: baseRouter + "products",
    PRODUCT_TYPE: baseRouter + "product-types",
    BLOG: baseRouter + "Blogs",
    CART: baseRouter + "Cart",
    ORDER: baseRouter + "orders",
    PERMISSION: baseRouter + "permissions",
    COMMON: {
        PROVINCES: baseCommonRouter + 'provinces',
        DISTRICT: baseCommonRouter + 'districts',
        PRICEs: baseCommonRouter + 'price-lists',
        PRODUCT_TYPE: baseCommonRouter + 'product-types',
        CALCULATION_UNIT: baseCommonRouter + 'calculation-units',
        CUSTOMER: baseCommonRouter + 'customers',
        POS_CUSTOMER_GROUPS: baseCommonRouter + 'pos/customer-groups',
        POS_EMPLOYEES: baseCommonRouter + 'pos/employees',
        VIBER_USERS: baseCommonRouter + 'viber-users',
        ZALO_USERS: baseCommonRouter + 'zalo-users',
        WAREHOUSES: baseCommonRouter + 'warehouses',
        SUPPLIERS: baseCommonRouter + 'suppliers',
    },
    REPORT: {
        COMMISSION: baseRouter + "reports/commission",
    },
    SETTING: baseRouter + 'settings',
    SYNC: baseRouter + 'syncs',
    WAREHOUSES: {
        INVENTORY: baseRouter + 'inventories',
        INVENTORY_VOUCHER: baseRouter + 'inventory-vouchers',
    }
}