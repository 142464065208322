import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

//#region CUSTOMER TYPE
export const getCustomerTypes = body => {
    var url = ApiRouters.CUSTOMER_TYPE;
    return api.get(url, { params: body });
};
export const deleteCustomerType = id => {
    var url = `${ApiRouters.CUSTOMER_TYPE}/${id}`;
    return api.delete(url);
};
export const getCustomerTypeDetail = id => {
    var url = `${ApiRouters.CUSTOMER_TYPE}/${id}`;
    return api.get(url);
};
export const createCustomerType = body => {
    var url = ApiRouters.CUSTOMER_TYPE;
    return api.post(url, body);
};
export const updateCustomerType = body => {
    var url = `${ApiRouters.CUSTOMER_TYPE}/${body.id}`;
    return api.put(url, body);
};
//#endregion

//#region CUSTOMER
export const getCustomers = body => {
    var url = ApiRouters.CUSTOMER;
    return api.get(url, { params: body });
};
export const deleteCustomer = id => {
    var url = `${ApiRouters.CUSTOMER}/${id}`;
    return api.delete(url);
};
export const getCustomerDetail = id => {
    var url = `${ApiRouters.CUSTOMER}/${id}`;
    return api.get(url);
};
export const createCustomer = body => {
    var url = ApiRouters.CUSTOMER;
    return api.post(url, body);
};
export const updateCustomer = body => {
    var url = `${ApiRouters.CUSTOMER}/${body.id}`;
    return api.put(url, body);
};
export const customerPurchaseOrder = body => {
    var url = `${ApiRouters.CUSTOMER}/${body.id}`;
    return api.put(url, body);
};
export const resetCustomerPassword = id => {
    var url = `${ApiRouters.CUSTOMER}/${id}/reset-password`;
    return api.put(url);
};
//#endregion
