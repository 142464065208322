import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as customerServices from '@iso/services/customer';
import commonAction from '@iso/redux/common/actions';

const { getDistricts, getWards } = commonAction;

//#region CUSTOMER TYPE
export function* getCustomerTypes() {
    yield takeEvery(actions.GET_CUSTOMER_TYPES, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(customerServices.getCustomerTypes, model);
            if (response.data.isSuccess === true) {
                var data = response.data.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_CUSTOMER_TYPES_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.CUSTOMER_LOADING_FALSE })
        }
    })
}
export function* deleteCustomerType() {
    yield takeEvery(actions.DELETE_CUSTOMER_TYPE, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(customerServices.deleteCustomerType, id);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* getCustomerTypeDetail() {
    yield takeEvery(actions.GET_CUSTOMER_TYPE_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(customerServices.getCustomerTypeDetail, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_CUSTOMER_TYPE_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.CUSTOMER_LOADING_FALSE })
        }
    })
}
export function* createCustomerType() {
    yield takeEvery(actions.CREATE_CUSTOMER_TYPE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(customerServices.createCustomerType, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* updateCustomerType() {
    yield takeEvery(actions.UPDATE_CUSTOMER_TYPE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(customerServices.updateCustomerType, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
//#endregion

//#region CUSTOMER
export function* getCustomers() {
    yield takeEvery(actions.GET_CUSTOMERS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(customerServices.getCustomers, model);
            if (response.data.isSuccess === true) {
                var data = response.data.data.items;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_CUSTOMERS_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.CUSTOMER_LOADING_FALSE })
        }
    })
}
export function* deleteCustomer() {
    yield takeEvery(actions.DELETE_CUSTOMER, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(customerServices.deleteCustomer, id);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* getCustomerDetail() {
    yield takeEvery(actions.GET_CUSTOMER_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(customerServices.getCustomerDetail, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_CUSTOMER_DETAIL_SUCCESS,
                    payload: { data }
                })

                if (data.provinceId)
                    yield put(getDistricts(data.provinceId))
                if (data.districtId)
                    yield put(getWards(data.districtId))

            }
        } catch (e) {
            yield put({ type: actions.CUSTOMER_LOADING_FALSE })
        }
    })
}

export function* createCustomer() {
    yield takeEvery(actions.CREATE_CUSTOMER, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(customerServices.createCustomer, model);
            if (response.data.isSuccess === true) {
                if (cbSuccess) {
                    yield cbSuccess(response.data.data);
                }
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* updateCustomer() {
    yield takeEvery(actions.UPDATE_CUSTOMER, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(customerServices.updateCustomer, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* resetCustomerPassword() {
    yield takeEvery(actions.RESET_CUSTOMER_PASSWORD, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(customerServices.resetCustomerPassword, id);
            if (response.data.isSuccess === true) {
                if (cbSuccess) {
                    yield cbSuccess(response.data.data);
                }
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* customerPurchaseOrder() {
    yield takeEvery(actions.CUSTOMER_PURCHASE_ORDER, function* ({ payload }) {
        const { model, id } = payload;
        try {
            var response = yield call(customerServices.customerPurchaseOrder, model, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.CUSTOMER_PURCHASE_ORDER_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.CUSTOMER_LOADING_FALSE })
        }
    })
}
//#endregion

export default function* rootSaga() {
    yield all([
        fork(getCustomerTypes),
        fork(deleteCustomerType),
        fork(getCustomerTypeDetail),
        fork(createCustomerType),
        fork(updateCustomerType),

        fork(getCustomers),
        fork(deleteCustomer),
        fork(getCustomerDetail),
        fork(createCustomer),
        fork(updateCustomer),
        fork(customerPurchaseOrder),
        fork(resetCustomerPassword),
    ])
}