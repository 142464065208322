const actions = {

    GET_SETTING_STORE_INFORMATIONS: 'GET_SETTING_STORE_INFORMATIONS',
    GET_SETTING_STORE_INFORMATIONS_SUCCESS: 'GET_SETTING_STORE_INFORMATIONS_SUCCESS',
    UPDATE_SETTING_STORE_INFORMATIONS: 'UPDATE_SETTING_STORE_INFORMATIONS',
    UPDATE_SETTING_STORE_INFORMATIONS_SUCCESS: 'UPDATE_SETTING_STORE_INFORMATIONS_SUCCESS',

    SETTING_LOADING_FALSE: 'SETTING_LOADING_FALSE',

    //#region CRUD
    getStoreInformations: () => ({
        type: actions.GET_SETTING_STORE_INFORMATIONS
    }),
    updateStoreInformations: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_SETTING_STORE_INFORMATIONS,
        payload: { model },
        cbSuccess, cbError
    }),
    //#endregion
}

export default actions;