import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@iso/services/warehouseInventoryVoucher';

//#region CRUD IMPORT VOUCHERS
export function* getImportVouchers() {
    yield takeEvery(actions.GET_IMPORT_VOUCHERS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(services.getImportVouchers, model);
            if (response.data.isSuccess === true) {
                var { items, total } = response.data.data;
                yield put({
                    type: actions.GET_IMPORT_VOUCHERS_SUCCESS,
                    payload: { data: items, totalItems: total }
                })
            }
        } catch (e) {
            yield put({ type: actions.INVENTORIE_VOUCHERS_LOADING_FALSE })
        }
    })
}

export function* getImportVoucherDetail() {
    yield takeEvery(actions.GET_IMPORT_VOUCHER_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(services.getImportVoucherDetail, id);
            if (response.data.isSuccess === true) {
                yield put({
                    type: actions.GET_IMPORT_VOUCHER_DETAIL_SUCCESS,
                    payload: { data: response.data.data }
                })
            }
        } catch (e) {
            yield put({ type: actions.INVENTORIE_VOUCHERS_LOADING_FALSE })
        }
    })
}

export function* createImportVouchers() {
    yield takeEvery(actions.CREATE_IMPORT_VOUCHER, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.createImportVoucher, model);
            if (response.data.isSuccess === true) {
                cbSuccess && (yield cbSuccess(response.data.data));
            }
        } catch (e) {
            if (e.response && e.response.data)
                cbError && (yield cbError(e.response.data?.error));
            else
                cbError(yield cbError());
        }
    })
}

export function* deleteImportVouchers() {
    yield takeEvery(actions.DELETE_IMPORT_VOUCHER, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(services.deleteImportVoucher, id);
            if (response.data.isSuccess === true) {
                yield cbSuccess(response.data.data);
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* approveImportVoucher() {
    yield takeEvery(actions.APPROVE_IMPORT_VOUCHER, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(services.approveImportVoucher, id);
            if (response.data.isSuccess === true) {
                yield cbSuccess(response.data.data);
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* cancelImportVoucher() {
    yield takeEvery(actions.CANCEL_IMPORT_VOUCHER, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(services.cancelImportVoucher, id);
            if (response.data.isSuccess === true) {
                yield cbSuccess(response.data.data);
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* checkedImportVoucher() {
    yield takeEvery(actions.CHECKED_IMPORT_VOUCHER, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(services.checkedImportVoucher, id);
            if (response.data.isSuccess === true) {
                yield cbSuccess(response.data.data);
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
//#endregion

//#region CRUD IMPORT VOUCHER_LINES
export function* getImportVoucherLines() {
    yield takeEvery(actions.GET_IMPORT_VOUCHER_LINES, function* ({ payload }) {
        const { model, voucherId } = payload;
        try {
            var response = yield call(services.getImportVoucherLines, voucherId, model);
            if (response.data.isSuccess === true) {
                var { items, total } = response.data.data;
                yield put({
                    type: actions.GET_IMPORT_VOUCHER_LINES_SUCCESS,
                    payload: { data: items, totalItems: total }
                })
            }
        } catch (e) {
            yield put({ type: actions.INVENTORIE_VOUCHERS_LOADING_FALSE })
        }
    })
}
export function* getImportVoucherLineDetail() {
    yield takeEvery(actions.GET_IMPORT_VOUCHER_LINE_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(services.getImportVoucherLineDetail, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_ORDER_LINE_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.ORDER_LOADING_FALSE })
        }
    })
}
export function* createImportVoucherLine() {
    yield takeEvery(actions.CREATE_IMPORT_VOUCHER_LINE, function* ({ payload, cbSuccess, cbError }) {
        const { body, voucherId } = payload;
        try {
            var response = yield call(services.createImportVoucherLine, voucherId, body);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* updateImportVoucherLine() {
    yield takeEvery(actions.UPDATE_IMPORT_VOUCHER_LINE, function* ({ payload, cbSuccess, cbError }) {
        const { lineId, voucherId, body } = payload;
        try {
            var response = yield call(services.updateImportVoucherLine, voucherId, lineId, body);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* deleteImportVoucherLine() {
    yield takeEvery(actions.DELETE_IMPORT_VOUCHER_LINE, function* ({ payload, cbSuccess, cbError }) {
        const { lineId, voucherId } = payload;
        try {
            var response = yield call(services.deleteImportVoucherLine, voucherId, lineId);
            if (response.data.isSuccess === true)
                yield cbSuccess();
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
//#endregion

export default function* rootSaga() {
    yield all([
        fork(getImportVouchers),
        fork(getImportVoucherDetail),
        fork(createImportVouchers),
        fork(deleteImportVouchers),
        fork(approveImportVoucher),
        fork(cancelImportVoucher),
        fork(checkedImportVoucher),        
        fork(getImportVoucherLines),
        fork(getImportVoucherLineDetail),
        fork(createImportVoucherLine),
        fork(updateImportVoucherLine),
        fork(deleteImportVoucherLine),         
    ])
}