import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@iso/services/order';
import * as _ from 'lodash';

//#region CRUD
export function* getOrders() {
    yield takeEvery(actions.GET_ORDERS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(services.getOrders, model);
            if (response.data.isSuccess === true) {
                var { items, total } = response.data.data;
                yield put({
                    type: actions.GET_ORDERS_SUCCESS,
                    payload: { data: items, totalItems: total }
                })
            }
        } catch (e) {
            yield put({ type: actions.ORDER_LOADING_FALSE })
        }
    })
}
export function* deleteOrder() {
    yield takeEvery(actions.DELETE_ORDER, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(services.deleteOrder, id);
            if (response.data.isSuccess === true)
                yield cbSuccess();
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* getOrderDetail() {
    yield takeEvery(actions.GET_ORDER_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(services.getOrderDetail, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_ORDER_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.ORDER_LOADING_FALSE })
        }
    })
}
export function* createOrder() {
    yield takeEvery(actions.CREATE_ORDER, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.createOrder, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess(response.data.data);
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* updateOrder() {
    yield takeEvery(actions.UPDATE_ORDER, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.updateOrder, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* updateOrderCompletedDate() {
    yield takeEvery(actions.UPDATE_ORDER_COMPLETED_DATE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.updateOrderCompletedDate, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* changeStatus() {
    yield takeEvery(actions.CHANGE_STATUS, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.changeStatus, model);
            if (response.data.isSuccess === true)
                yield cbSuccess();
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* getOrderStates() {
    yield takeEvery(actions.GET_ORDER_STATES, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(services.getOrderStates, id);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_ORDER_STATES_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.ORDER_LOADING_FALSE })
        }
    })
}

export function* getNewOrders() {
    yield takeEvery(actions.GET_NEW_ORDERS, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(services.getNewOrders, id);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_NEW_ORDERS_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.ORDER_LOADING_FALSE })
        }
    })
}

export function* mergeNewOrders() {
    yield takeEvery(actions.MERGE_NEW_ORDERS, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.mergeNewOrders, model);
            if (response.data.isSuccess === true)
                yield cbSuccess();
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

//#endregion

//#region ORDER_LINE
export function* getOrderLines() {
    yield takeEvery(actions.GET_ORDER_LINES, function* ({ payload }) {
        const { model, orderId } = payload;
        try {
            var response = yield call(services.getOrderLines, model, orderId);
            if (response.data.isSuccess === true) {
                var { items, total } = response.data.data;
                yield put({
                    type: actions.GET_ORDER_LINES_SUCCESS,
                    payload: { data: items, totalItems: total }
                })
            }
        } catch (e) {
            yield put({ type: actions.ORDER_LOADING_FALSE })
        }
    })
}
export function* deleteOrderLine() {
    yield takeEvery(actions.DELETE_ORDER_LINE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.deleteOrderLine, model);
            if (response.data.isSuccess === true)
                yield cbSuccess();
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* getOrderLineDetail() {
    yield takeEvery(actions.GET_ORDER_LINE_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(services.getOrderLineDetail, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_ORDER_LINE_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.ORDER_LOADING_FALSE })
        }
    })
}
export function* createOrderLine() {
    yield takeEvery(actions.CREATE_ORDER_LINE, function* ({ payload, cbSuccess, cbError }) {
        const { model, orderId } = payload;
        try {
            var response = yield call(services.createOrderLine, model, orderId);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* updateOrderLine() {
    yield takeEvery(actions.UPDATE_ORDER_LINE, function* ({ payload, cbSuccess, cbError }) {
        const { model, orderId } = payload;
        try {
            var response = yield call(services.updateOrderLine, model, orderId);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
//#endregion

export default function* rootSaga() {
    yield all([
        fork(getOrders),
        fork(deleteOrder),
        fork(getOrderDetail),
        fork(createOrder),
        fork(updateOrder),
        fork(getOrderLines),
        fork(deleteOrderLine),
        fork(getOrderLineDetail),
        fork(createOrderLine),
        fork(updateOrderLine),
        fork(getOrderStates),
        fork(changeStatus),
        fork(getNewOrders),
        fork(mergeNewOrders),  
        fork(updateOrderCompletedDate),      
    ])
}