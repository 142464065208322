import actions from "./actions";

const initState = {
    listRoles: [],
    roles: [],
    roleDetail: {},
    rolePermissions: [],

    permissionsList: [],
    loading: false,
    totalItems: 0,

};

export default function userReducer(state = initState, action) {
    switch (action.type) {
        //#region CRUD
        case actions.GET_ROLES:
            return { ...state, loading: true, totalItems: 0 };
        case actions.GET_ROLES_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, roles: res, totalItems, loading: false };
        }

        case actions.GET_ALL_ROLES:
            return { ...state, loading: true, totalItems: 0 };
        case actions.GET_ALL_ROLES_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, listRoles: res, totalItems, loading: false };
        }

        case actions.GET_ROLE_DETAIL:
            return { ...state, loading: true, roleDetail: {}, totalItems: 0 };
        case actions.GET_ROLE_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, roleDetail: res, loading: false };
        }
        //#endregion

        //#region ACCESS PERMISSION
        case actions.ROLE_ACCESS_PERMISSTION:
            return { ...state, loading: true };
        case actions.ROLE_ACCESS_PERMISSTION_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, rolePermissions: res, loading: false };
        }
        //#endregion

        case actions.GET_PERMISSION:
            return { ...state, loading: true, totalItems: 0 };
        case actions.GET_PERMISSION_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, permissionsList: res, totalItems, loading: false };
        }

        default:
            return state;
    }
}
