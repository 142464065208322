export var products = [
    {
        id: 1,
        code: 'PCT',
        name: 'Paracetamol',
        type: 'Thuốc viên',
        description: 'Đây là thuốc abc',
        thumbnail: 'https://login.medlatec.vn/ImagePath/imageslead/20200421/20200421_paracetamol-3.jpg',
    },
    {
        id: 2,
        code: 'PCT2',
        name: 'Paracetamol2',
        type: 'Thuốc viên',
        description: 'Đây là thuốc abc',
        thumbnail: 'https://login.medlatec.vn/ImagePath/imageslead/20200421/20200421_paracetamol-3.jpg',
    },
    {
        id: 3,
        code: 'PCT3',
        name: 'Paracetamol4',
        type: 'Thuốc viên',
        description: 'Đây là thuốc abc',
        thumbnail: 'https://login.medlatec.vn/ImagePath/imageslead/20200421/20200421_paracetamol-3.jpg',
    },
    {
        id: 4,
        code: 'PCT4',
        name: 'Paracetamol4',
        type: 'Thuốc viên',
        description: 'Đây là thuốc abc',
        thumbnail: 'https://login.medlatec.vn/ImagePath/imageslead/20200421/20200421_paracetamol-3.jpg',
    },
    {
        id: 5,
        code: 'PCT5',
        name: 'Paracetamol5',
        type: 'Thuốc viên',
        description: 'Đây là thuốc abc',
        thumbnail: 'https://login.medlatec.vn/ImagePath/imageslead/20200421/20200421_paracetamol-3.jpg',
    },
    {
        id: 6,
        code: 'PCT6',
        name: 'Paracetamol6',
        type: 'Thuốc viên',
        description: 'Đây là thuốc abc',
        thumbnail: 'https://login.medlatec.vn/ImagePath/imageslead/20200421/20200421_paracetamol-3.jpg',
    },
]
export const productTypes = [
    { id: 1, name: 'Thuốc trị bệnh' },
    { id: 2, name: 'Thực phẩm chức năng' },
    { id: 3, name: 'Phụ kiện' },
    { id: 4, name: 'Khẩu trang' },
]