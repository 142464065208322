const actions = {

    GET_REPORT_COMMISSION: 'GET_REPORT_COMMISSION',
    GET_REPORT_COMMISSION_SUCCESS: 'GET_REPORT_COMMISSION_SUCCESS',

    GET_REPORT_FAIL: 'GET_REPORT_FAIL',

    //#region GET
    getReportCommission: (model, cbSuccess, cbError) => ({
        type: actions.GET_REPORT_COMMISSION, 
        cbSuccess, 
        cbError,
        payload: { model }
    }),

    //#endregion
}

export default actions;