import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@iso/services/warehouseInventories';
import * as _ from 'lodash';

//#region CRUD
export function* getInventories() {
    yield takeEvery(actions.GET_INVENTORIES, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(services.getList, model);
            if (response.data.isSuccess === true) {
                var { items, total } = response.data.data;
                yield put({
                    type: actions.GET_INVENTORIES_SUCCESS,
                    payload: { data: items, totalItems: total }
                })
            }
        } catch (e) {
            yield put({ type: actions.INVENTORY_LOADING_FALSE })
        }
    })
}

export function* getInventoriesByExpirationDate() {
    yield takeEvery(actions.GET_INVENTORIES_BY_EXPIRATION_DATE, function* ({ payload, cbSuccess, cbError }) {
        const { productId } = payload;
        try {
            var response = yield call(services.getInventoriesByExpirationDate, productId);
            if (response.data.isSuccess === true) {
                var { items, total } = response.data.data;
                yield put({
                    type: actions.GET_INVENTORIES_BY_EXPIRATION_DATE_SUCCESS,
                    payload: { data: items, totalItems: total }
                });
                yield cbSuccess(items);
            }
        } catch (e) {
            yield put({ type: actions.INVENTORY_LOADING_FALSE });
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* createInventoriesByExpirationDate() {
    yield takeEvery(actions.CREATE_INVENTORIES_BY_EXPIRATION_DATE, function* ({ payload, cbSuccess, cbError }) {
        const { productId, body } = payload;
        try {
            var response = yield call(services.createInventoriesByExpirationDate, productId, body);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            yield put({ type: actions.INVENTORY_LOADING_FALSE });
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}


export function* updateInventoriesByExpirationDate() {
    yield takeEvery(actions.UPDATE_INVENTORIES_BY_EXPIRATION_DATE, function* ({ payload, cbSuccess, cbError }) {
        const { productId, inventoryId, body } = payload;
        try {
            var response = yield call(services.updateInventoriesByExpirationDate, productId, inventoryId, body);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            yield put({ type: actions.INVENTORY_LOADING_FALSE });
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getInventories),
        fork(getInventoriesByExpirationDate),
        fork(createInventoriesByExpirationDate),
        fork(updateInventoriesByExpirationDate),
    ])
}