import actions from './actions';

const initState = {
    productTypes: [],
    productTypeDetail: {},
    loading: false,
    loadingDetail: false,
    totalItems: 0,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        //#region CRUD
        case actions.GET_PRODUCT_TYPES:
            return { ...state, loading: true, totalItems: 0 };
        case actions.GET_PRODUCT_TYPES_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, productTypes: res, totalItems: totalItems, loading: false };
        }

        case actions.GET_PRODUCT_TYPE_DETAIL:
            return { ...state, productTypeDetail: {}, loadingDetail: true };
        case actions.GET_PRODUCT_TYPE_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, productTypeDetail: res, loadingDetail: false };
        }
        //#endregion

        case actions.PRODUCT_TYPE_LOADING_FALSE:
            return { ...state, loading: false };

        default:
            return state;

    }
}
