import actions from './actions';

const initState = {
    groups: [],
    provinces: [],
    districts: [],
    wards: [],
    priceLists: [],
    productTypes: [],
    calculationUnits: [],
    customers: [],
    posCustomerGroups: [],
    posEmployees: [],
    versionApi: "0",
    loading: false,
    viberUsers: [],
    zaloUsers: [],
    warehouses: [],
    suppliers: [],
};

export default function accountReducer(state = initState, action) {
    switch (action.type) {

        case actions.GET_VERSION_API_SUCCESS: {
            return { ...state, versionApi: action.payload.data };
        }

        //#region GROUP
        case actions.GET_GROUPS_COMMON:
            return { ...state };
        case actions.GET_GROUPS_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : [];
            return { ...state, groups: res };
        }
        //#endregion

        //#region ADDRESS
        case actions.GET_PROVINCE_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : [];
            return { ...state, provinces: res };
        }
        case actions.GET_DISTRICT_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : [];
            return { ...state, districts: res };
        }
        case actions.RESET_DISTRICT_COMMON:
            return { ...state, districts: [] };

        case actions.GET_WARD_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : [];
            return { ...state, wards: res };
        }
        case actions.RESET_WARD_COMMON:
            return { ...state, wards: [] };
        //#endregion

        //#region 
        case actions.GET_PRICEs_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, priceLists: res };
        }
        case actions.GET_PRODUCT_TYPE_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, productTypes: res };
        }
        case actions.GET_CALCULATE_UNIT_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, calculationUnits: res };
        }
        case actions.GET_CUSTOMER_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, customers: res };
        }
        case actions.GET_POS_CUSTOMER_GROUPS_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, posCustomerGroups: res };
        }
        case actions.GET_POS_EMPLOYEES_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, posEmployees: res };
        }
        case actions.GET_VIBER_USERS_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, viberUsers: res };
        }
        case actions.GET_ZALO_USERS_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, zaloUsers: res };
        }

        case actions.GET_WAREHOUSES_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, warehouses: res };
        }
        case actions.GET_SUPPLIERS_COMMON_SUCCESS: {
            let { data } = action.payload;
            let res = data || [];
            return { ...state, suppliers: res };
        }
        
        //#endregion

        case actions.COMMON_RESPONSE_ERROR:
            return { ...state, loading: false }

        default:
            return state;

    }
}
