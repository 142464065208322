import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as roleServices from '@iso/services/role';

//#region CRUD
export function* getRoles() {
    yield takeEvery(actions.GET_ROLES, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(roleServices.getRoles, model);
            if (response.data.isSuccess === true) {
                var { items, total } = response.data.data;
                yield put({
                    type: actions.GET_ROLES_SUCCESS,
                    payload: { data: items, totalItems: total }
                })
            }
        } catch (e) {
            yield put({ type: actions.ROLE_LOADING_FALSE })
        }
    })
}

export function* getAllRoles() {
    yield takeEvery(actions.GET_ALL_ROLES, function* () {
        try {
            var response = yield call(roleServices.getAllRoles);
            if (response.data.isSuccess === true) {
                var { items, total } = response.data.data;
                yield put({
                    type: actions.GET_ALL_ROLES_SUCCESS,
                    payload: { data: items, totalItems: total }
                })
            }
        } catch (e) {
            yield put({ type: actions.ROLE_LOADING_FALSE })
        }
    })
}

export function* createRole() {
    yield takeEvery(actions.CREATE_ROLE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(roleServices.createRole, model);
            if (response.data.isSuccess === true)
                yield cbSuccess();
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* updateRole() {
    yield takeEvery(actions.UPDATE_ROLE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(roleServices.updateRole, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* deleteRole() {
    yield takeEvery(actions.DELETE_ROLE, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(roleServices.deleteRole, id);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* getRoleDetail() {
    yield takeEvery(actions.GET_ROLE_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(roleServices.getRoleDetail, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_ROLE_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.ROLE_LOADING_FALSE })
        }
    })
}

//#endregion

//#region ACCESS PERMISSION
export function* roleAccessPermission() {
    yield takeEvery(actions.ROLE_ACCESS_PERMISSTION, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(roleServices.roleAccessPermission, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.ROLE_ACCESS_PERMISSTION_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.ROLE_LOADING_FALSE })
        }
    })
}
export function* updateRoleAccessPermission() {
    yield takeEvery(actions.UPDATE_ROLE_ACCESS_PERMISSTION, function* ({ payload, cbSuccess, cbError }) {
        const { roleId, model } = payload;
        try {
            var response = yield call(roleServices.updateRoleAccessPermission, roleId, model);
            if (response.data.isSuccess === true)
                yield cbSuccess();
            else
                yield cbError(response.data?.error);
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
//#endregion

export function* getPermissions() {
    yield takeEvery(actions.GET_PERMISSION, function* ({ payload }) {
        try {
            var response = yield call(roleServices.getPermissions);
            if (response.data.isSuccess === true) {
                var { items, total } = response.data.data;
                yield put({
                    type: actions.GET_PERMISSION_SUCCESS,
                    payload: { data: items, totalItems: total }
                })
            }
        } catch (e) {
            yield put({ type: actions.ROLE_LOADING_FALSE })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getRoles),
        fork(getAllRoles),
        fork(createRole),
        fork(updateRole),
        fork(deleteRole),
        fork(getRoleDetail),
        fork(roleAccessPermission),
        fork(updateRoleAccessPermission),
        fork(getPermissions),
    ])
}