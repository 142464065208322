const actions = {
    INVENTORIE_VOUCHERS_LOADING_FALSE: 'INVENTORIE_VOUCHERS_LOADING_FALSE',

    //#region IMPORT VOUCHERS
    GET_IMPORT_VOUCHERS: 'GET_IMPORT_VOUCHERS',
    GET_IMPORT_VOUCHERS_SUCCESS: 'GET_IMPORT_VOUCHER_SUCCESS',
    GET_IMPORT_VOUCHER_DETAIL: 'GET_IMPORT_VOUCHER_DETAIL',
    GET_IMPORT_VOUCHER_DETAIL_SUCCESS: 'GET_IMPORT_VOUCHER_DETAIL_SUCCESS',

    CREATE_IMPORT_VOUCHER: 'CREATE_IMPORT_VOUCHER',
    CREATE_IMPORT_VOUCHER_SUCCESS: 'CREATE_IMPORT_VOUCHER_SUCCESS',
    DELETE_IMPORT_VOUCHER: 'DELETE_IMPORT_VOUCHER',
    DELETE_IMPORT_VOUCHER_SUCCESS: 'DELETE_IMPORT_VOUCHER_SUCCESS',

    APPROVE_IMPORT_VOUCHER: 'APPROVE_IMPORT_VOUCHER',
    CANCEL_IMPORT_VOUCHER: 'CANCEL_IMPORT_VOUCHER',
    CHECKED_IMPORT_VOUCHER: "CHECKED_IMPORT_VOUCHER",

    getImportVouchers: model => ({
        type: actions.GET_IMPORT_VOUCHERS,
        payload: { model }
    }),
    getImportVoucherDetail: id => ({
        type: actions.GET_IMPORT_VOUCHER_DETAIL,
        payload: { id }
    }),
    createImportVoucher: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_IMPORT_VOUCHER,
        payload: { model },
        cbSuccess, cbError
    }),
    deleteImportVoucher: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_IMPORT_VOUCHER,
        payload: { id },
        cbSuccess, cbError
    }),
    approveImportVoucher: (id, cbSuccess, cbError) => ({
        type: actions.APPROVE_IMPORT_VOUCHER,
        payload: { id },
        cbSuccess, cbError
    }),
    cancelImportVoucher: (id, cbSuccess, cbError) => ({
        type: actions.CANCEL_IMPORT_VOUCHER,
        payload: { id },
        cbSuccess, cbError
    }),
    checkedImportVoucher: (id, cbSuccess, cbError) => ({
        type: actions.CHECKED_IMPORT_VOUCHER,
        payload: { id },
        cbSuccess, cbError
    }),
    //#endregion

    //#region IMPORT VOUCHER LINES

    GET_IMPORT_VOUCHER_LINES: 'GET_IMPORT_VOUCHER_LINES',
    GET_IMPORT_VOUCHER_LINES_SUCCESS: 'GET_IMPORT_VOUCHER_LINES_SUCCESS',

    GET_IMPORT_VOUCHER_LINE_DETAIL: 'GET_IMPORT_VOUCHER_LINE_DETAIL',
    GET_IMPORT_VOUCHER_LINE_DETAIL_SUCCESS: 'GET_IMPORT_VOUCHER_LINE_DETAIL_SUCCESS',

    CREATE_IMPORT_VOUCHER_LINE: 'CREATE_IMPORT_VOUCHER_LINE',
    UPDATE_IMPORT_VOUCHER_LINE: 'UPDATE_IMPORT_VOUCHER_LINE',
    DELETE_IMPORT_VOUCHER_LINE: 'DELETE_IMPORT_VOUCHER_LINE',
    UPDATE_IMPORT_VOUCHER_COMPLETED_DATE: 'UPDATE_IMPORT_VOUCHER_COMPLETED_DATE',

    getImportVoucherLines: (voucherId, model) => ({
        type: actions.GET_IMPORT_VOUCHER_LINES,
        payload: { model, voucherId }
    }),
    getImportVoucherLineDetail: id => ({
        type: actions.GET_IMPORT_VOUCHER_LINE_DETAIL,
        payload: { id }
    }),
    createImportVoucherLine: (voucherId, body, cbSuccess, cbError) => ({
        type: actions.CREATE_IMPORT_VOUCHER_LINE,
        payload: { body, voucherId },
        cbSuccess, cbError
    }),
    updateImportVoucherLine: (voucherId, lineId, body, cbSuccess, cbError) => ({
        type: actions.UPDATE_IMPORT_VOUCHER_LINE,
        payload: { lineId, voucherId, body },
        cbSuccess, cbError
    }),
    deleteImportVoucherLine: (voucherId, lineId, cbSuccess, cbError) => ({
        type: actions.DELETE_IMPORT_VOUCHER_LINE,
        payload: { voucherId, lineId },
        cbSuccess, cbError
    }),
    //#endregion    
}

export default actions;