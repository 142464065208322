const actions = {

    GET_PRODUCTS: 'GET_PRODUCTS',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',

    GET_PRODUCT_DETAIL: 'GET_PRODUCT_DETAIL',
    GET_PRODUCT_DETAIL_SUCCESS: 'GET_PRODUCT_DETAIL_SUCCESS',

    CREATE_PRODUCT: 'CREATE_PRODUCT',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT',

    DELETE_PRODUCT: 'DELETE_PRODUCT',

    UPDATE_PRODUCT_SHOW_FIRST_PAGE: 'UPDATE_PRODUCT_SHOW_FIRST_PAGE',
    DELETE_PRODUCT_SHOW_FIRST_PAGE: 'DELETE_PRODUCT_SHOW_FIRST_PAGE',

    GET_PRODUCT_PRICE_LIST: 'GET_PRODUCT_PRICE_LIST',
    GET_PRODUCT_PRICE_LIST_SUCCESS: 'GET_PRODUCT_PRICE_LIST_SUCCESS',

    UPDATE_PRODUCT_PRICE_LIST: 'UPDATE_PRODUCT_PRICE_LIST',

    PRODUCT_LOADING_FALSE: 'PRODUCT_LOADING_FALSE',

    //#region CRUD
    getProducts: model => ({
        type: actions.GET_PRODUCTS,
        payload: { model }
    }),
    deleteProduct: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_PRODUCT,
        payload: { id },
        cbSuccess, cbError
    }),
    getProductDetail: id => ({
        type: actions.GET_PRODUCT_DETAIL,
        payload: { id }
    }),
    createProduct: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_PRODUCT,
        payload: { model },
        cbSuccess, cbError
    }),
    updateProduct: (id, model, cbSuccess, cbError) => ({
        type: actions.UPDATE_PRODUCT,
        payload: { id, model },
        cbSuccess, cbError
    }),

    RESET_PRODUCT_DETAIL: 'RESET_PRODUCT_DETAIL',
    resetProductDetail: () => ({
        type: actions.RESET_PRODUCT_DETAIL,
    }),
    //#endregion

    updateProductShowFirstPage: (productId, cbSuccess, cbError) => ({
        type: actions.UPDATE_PRODUCT_SHOW_FIRST_PAGE,
        payload: { productId },
        cbSuccess, cbError
    }),

    deleteProductShowFirstPage: (productId, cbSuccess, cbError) => ({
        type: actions.DELETE_PRODUCT_SHOW_FIRST_PAGE,
        payload: { productId },
        cbSuccess, cbError
    }),

    getProductPriceList: (productId, model) => ({
        type: actions.GET_PRODUCT_PRICE_LIST,
        payload: { productId, model }
    }),

    updateProductPriceList: (productId, model, cbSuccess, cbError) => ({
        type: actions.UPDATE_PRODUCT_PRICE_LIST,
        payload: { productId, model },
        cbSuccess, cbError
    }),
}

export default actions;