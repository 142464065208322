const actions = {
    SYNC_DATA_CUSTOMERS: 'SYNC_DATA_CUSTOMERS',
    SYNC_DATA_PRODUCTS: 'SYNC_DATA_PRODUCTS',
    SYNC_DATA_USERS: 'SYNC_DATA_USERS',
    SYNC_DATA_FROM_SAIGON_PHARMA: 'SYNC_DATA_FROM_SAIGON_PHARMA',

    SYNC_DATA_LOADING_FALSE: 'SYNC_DATA_LOADING_FALSE',

    //#region CRUD
    syncCustomers: (cbSuccess, cbError) => ({
        type: actions.SYNC_DATA_CUSTOMERS,
        cbSuccess, cbError
    }),

    syncProducts: (cbSuccess, cbError) => ({
        type: actions.SYNC_DATA_PRODUCTS,
        cbSuccess, cbError
    }),

    syncUsers: (cbSuccess, cbError) => ({
        type: actions.SYNC_DATA_USERS,
        cbSuccess, cbError
    }),

    syncFromSgPharma: (cbSuccess, cbError) => ({
        type: actions.SYNC_DATA_FROM_SAIGON_PHARMA,
        cbSuccess, cbError
    }),
    //#endregion
}

export default actions;