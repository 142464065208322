const actions = {

    //#region VERSION
    GET_VERSION_API: 'GET_VERSION_API',
    GET_VERSION_API_SUCCESS: 'GET_VERSION_API_SUCCESS',
    GET_VERSION_API_ERROR: 'GET_VERSION_API_ERROR',

    getVersionApi: () => ({
        type: actions.GET_VERSION_API,
    }),
    //#endregion

    //#region GROUPS
    GET_GROUPS_COMMON: 'GET_GROUPS_COMMON',
    GET_GROUPS_COMMON_SUCCESS: 'GET_GROUPS_COMMON_SUCCESS',
    getGroupsCommon: () => ({
        type: actions.GET_GROUPS_COMMON,
    }),
    //#endregion

    //#region  ADDRESS
    GET_PROVINCE_COMMON: 'GET_PROVINCE_COMMON',
    GET_PROVINCE_COMMON_SUCCESS: 'GET_PROVINCE_COMMON_SUCCESS',
    getProvinces: () => ({
        type: actions.GET_PROVINCE_COMMON,
    }),

    GET_DISTRICT_COMMON: 'GET_DISTRICT_COMMON',
    GET_DISTRICT_COMMON_SUCCESS: 'GET_DISTRICT_COMMON_SUCCESS',
    RESET_DISTRICT_COMMON: 'RESET_DISTRICT_COMMON',
    getDistricts: provinceId => ({
        type: actions.GET_DISTRICT_COMMON,
        payload: { provinceId }
    }),
    resetDistricts: () => ({
        type: actions.RESET_DISTRICT_COMMON,
    }),

    GET_WARD_COMMON: 'GET_WARD_COMMON',
    GET_WARD_COMMON_SUCCESS: 'GET_WARD_COMMON_SUCCESS',
    RESET_WARD_COMMON: 'RESET_WARD_COMMON',
    getWards: districtId => ({
        type: actions.GET_WARD_COMMON,
        payload: { districtId }
    }),
    resetWards: () => ({
        type: actions.RESET_WARD_COMMON,
    }),
    //#endregion

    //#region PRICE
    GET_PRICEs_COMMON: 'GET_PRICEs_COMMON',
    GET_PRICEs_COMMON_SUCCESS: 'GET_PRICEs_COMMON_SUCCESS',
    getPrices: () => ({
        type: actions.GET_PRICEs_COMMON,
    }),
    //#endregion

    //#region PRODUCT
    GET_PRODUCT_TYPE_COMMON: 'GET_PRODUCT_TYPE_COMMON',
    GET_PRODUCT_TYPE_COMMON_SUCCESS: 'GET_PRODUCT_TYPE_COMMON_SUCCESS',
    getProductTypes: () => ({
        type: actions.GET_PRODUCT_TYPE_COMMON,
    }),
    //#endregion

    //#region CALCULATE_UNIT
    GET_CALCULATE_UNIT_COMMON: 'GET_CALCULATE_UNIT_COMMON',
    GET_CALCULATE_UNIT_COMMON_SUCCESS: 'GET_CALCULATE_UNIT_COMMON_SUCCESS',
    getCalculateUnit: () => ({
        type: actions.GET_CALCULATE_UNIT_COMMON,
    }),
    //#endregion

    //#region CUSTOMER
    GET_CUSTOMER_COMMON: 'GET_CUSTOMER_COMMON',
    GET_CUSTOMER_COMMON_SUCCESS: 'GET_CUSTOMER_COMMON_SUCCESS',
    getCustomersCommon: () => ({
        type: actions.GET_CUSTOMER_COMMON,
    }),
    //#endregion

    //#region POS CUSTOMER GROUPS
    GET_POS_CUSTOMER_GROUPS: 'GET_POS_CUSTOMER_GROUPS',
    GET_POS_CUSTOMER_GROUPS_SUCCESS: 'GET_POS_CUSTOMER_GROUPS_SUCCESS',
    getPosCustomerGroups: () => ({
        type: actions.GET_POS_CUSTOMER_GROUPS,
    }),

    //#region POS EMPLOYEES
    GET_POS_EMPLOYEES: 'GET_POS_EMPLOYEES',
    GET_POS_EMPLOYEES_SUCCESS: 'GET_POS_EMPLOYEES_SUCCESS',
    getPosEmployees: () => ({
        type: actions.GET_POS_EMPLOYEES,
    }),

    //#region GET VIBER USERS
    GET_VIBER_USERS: 'GET_VIBER_USERS',
    GET_VIBER_USERS_SUCCESS: 'GET_VIBER_USERS_SUCCESS',
    getViberUsers: () => ({
        type: actions.GET_VIBER_USERS,
    }),

    //#region GET ZALO USERS
    GET_ZALO_USERS: 'GET_ZALO_USERS',
    GET_ZALO_USERS_SUCCESS: 'GET_ZALO_USERS_SUCCESS',
    getZaloUsers: () => ({
        type: actions.GET_ZALO_USERS,
    }),

    //#region WAREHOUSES
    GET_WAREHOUSES_COMMON: 'GET_WAREHOUSES_COMMON',
    GET_WAREHOUSES_COMMON_SUCCESS: 'GET_WAREHOUSES_COMMON_SUCCESS',
    getWarehousesCommon: (model) => ({
        type: actions.GET_WAREHOUSES_COMMON,
        payload: { model }
    }),
    //#endregion

    //#region WAREHOUSES
    GET_SUPPLIERS_COMMON: 'GET_SUPPLIERS_COMMON',
    GET_SUPPLIERS_COMMON_SUCCESS: 'GET_SUPPLIERS_COMMON_SUCCESS',
    getSuppliersCommon: (model) => ({
        type: actions.GET_SUPPLIERS_COMMON,
        payload: { model }
    }),
    //#endregion   

    COMMON_RESPONSE_ERROR: 'COMMON_RESPONSE_ERROR'
}

export default actions;