import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getVersionApi = () => {
    var url = `${ApiRouters.COMMON}/GetVersionApi`;
    return api.get(url);
};

//#region ADDRESS 
export const getGroupsCommon = () => {
    var url = `${ApiRouters.COMMON}/Groups`;
    return api.get(url);
};
//#endregion

//#region ADDRESS 
export const getProvinces = () => {
    var url = `${ApiRouters.COMMON.PROVINCES}`;
    return api.get(url);
};
export const getDistricts = provinceId => {
    var url = `${ApiRouters.COMMON.PROVINCES}/${provinceId}/districts`;
    return api.get(url);
};
export const getWards = districtId => {
    var url = `${ApiRouters.COMMON.DISTRICT}/${districtId}/wards`;
    return api.get(url);
};
//#endregion

//#region PRICE
export const getPrices = () => {
    var url = `${ApiRouters.COMMON.PRICEs}`;
    return api.get(url);
};
export const getProductTypes = () => {
    var url = `${ApiRouters.COMMON.PRODUCT_TYPE}`;
    return api.get(url);
};
export const getCalculateUnit = () => {
    var url = `${ApiRouters.COMMON.CALCULATION_UNIT}`;
    return api.get(url);
};
export const getCustomersCommon = () => {
    var url = `${ApiRouters.COMMON.CUSTOMER}`;
    return api.get(url);
};
export const getPosCustomerGroups = () => {
    var url = `${ApiRouters.COMMON.POS_CUSTOMER_GROUPS}`;
    return api.get(url);
};
export const getPosEmployees = () => {
    var url = `${ApiRouters.COMMON.POS_EMPLOYEES}`;
    return api.get(url);
};
export const getWarehousesCommon = (params) => {
    var url = `${ApiRouters.COMMON.WAREHOUSES}`;
    return api.get(url, { params });
};

export const getSuppliersCommon = (params) => {
    var url = `${ApiRouters.COMMON.SUPPLIERS}`;
    return api.get(url, { params });
};

export const getViberUsers = () => {
    var url = `${ApiRouters.COMMON.VIBER_USERS}`;
    return api.get(url);
};

export const getZaloUsers = () => {
    var url = `${ApiRouters.COMMON.ZALO_USERS}`;
    return api.get(url);
};

//#endregion
