import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Account from '@iso/redux/account/reducer';
import Common from '@iso/redux/common/reducer';
import User from '@iso/redux/users/reducer';
import Role from '@iso/redux/roles/reducer';
import Product from '@iso/redux/products/reducer';
import ProductType from '@iso/redux/productTypes/reducer';
import Order from '@iso/redux/orders/reducer';
import Customer from '@iso/redux/customers/reducer';
import Report from '@iso/redux/reports/reducer';
import Setting from '@iso/redux/settings/reducer';
import Sync from '@iso/redux/syncs/reducer';
import Inventory from '@iso/redux/warehouses/inventories/reducer';
import InventoryVoucher from '@iso/redux/warehouses/inventoryVouchers/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  Account,
  Common,
  User,
  Role,
  Product,
  ProductType,
  Order,
  Customer,
  Report,
  Setting,
  Sync,
  Inventory,
  InventoryVoucher,
});
