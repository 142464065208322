import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@iso/services/setting';
import * as _ from 'lodash';

//#region CRUD
export function* getStoreInformations() {
    yield takeEvery(actions.GET_SETTING_STORE_INFORMATIONS, function* () {      
        try {
            var response = yield call(services.getStoreInformations);
            if (response.data.isSuccess === true) {
                var data = response.data.data;               
                yield put({
                    type: actions.GET_SETTING_STORE_INFORMATIONS_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.SETTING_LOADING_FALSE })
        }
    })
}


export function* updateStoreInformations() {
    yield takeEvery(actions.UPDATE_SETTING_STORE_INFORMATIONS, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.updateStoreInformations, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
//#endregion

export default function* rootSaga() {
    yield all([       
        fork(getStoreInformations),
        fork(updateStoreInformations),
    ])
}