import actions from './actions';

const initState = {
    products: [],
    productDetail: {},
    loading: false,
    totalItems: 0,
    productPriceList: [],
};

export default function productReducer(state = initState, action) {
    switch (action.type) {

        //#region CRUD
        case actions.GET_PRODUCTS:
            return { ...state, loading: true, totalItems: 0 };
        case actions.GET_PRODUCTS_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, products: res, totalItems: totalItems, loading: false };
        }

        case actions.GET_PRODUCT_DETAIL:
            return { ...state, productDetail: {}, loading: true };
        case actions.GET_PRODUCT_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, productDetail: res, loading: false };
        }
        case actions.GET_PRODUCT_DETAIL_ERROR:
            return { ...state, loading: false };
        //#endregion

        case actions.RESET_PRODUCT_DETAIL:
            return { ...state, productDetail: {} };
        case actions.PRODUCT_LOADING_FALSE:
            return { ...state, loading: false };

        case actions.GET_PRODUCT_PRICE_LIST:
            return { ...state, loadingPriceList: true };
        case actions.GET_PRODUCT_PRICE_LIST_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : [];
            return { ...state, productPriceList: res, loadingPriceList: false };
        }

        default:
            return state;

    }
}
