import actions from './actions';

const initState = {
    loading: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        default:
            return state;

    }
}
