import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const syncCustomers = () => {
    var url = `${ApiRouters.SYNC}/sync-data-customers`;
    return api.post(url);
};

export const syncProducts = () => {
    var url = `${ApiRouters.SYNC}/sync-data-products`;
    return api.post(url);
};

export const syncUsers = () => {
    var url = `${ApiRouters.SYNC}/sync-data-users`;
    return api.post(url);
};

export const syncFromSgPharma = () => {
    var url = `${ApiRouters.SYNC}/sync-data-from-sg-pharma`;
    return api.post(url);
};
