import actions from './actions';

const initState = {
    inventories: [],
    loading: false,
    totalItems: 0,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        //#region CRUD
        case actions.GET_INVENTORIES:
            return { ...state, loading: true, inventories: [], totalItems: 0 };
        case actions.GET_INVENTORIES_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, inventories: res, totalItems: totalItems, loading: false };
        }

        case actions.GET_INVENTORIES_BY_EXPIRATION_DATE:
            return { ...state, loading: true };
        case actions.GET_INVENTORIES_BY_EXPIRATION_DATE_SUCCESS: {
            return { ...state, loading: false };
        }
        //#endregion

        case actions.INVENTORY_LOADING_FALSE:
            return { ...state, loading: false, loadingLines: false };

        default:
            return state;

    }
}
