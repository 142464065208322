import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

//Import Vouchers
export const getImportVouchers = (params) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import`;
    return api.get(url, { params });
};

export const getImportVoucherDetail = (id) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${id}`;
    return api.get(url);
};

export const createImportVoucher = (body) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import`;
    return api.post(url, body);
};

export const deleteImportVoucher = (id) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${id}`;
    return api.delete(url);
};

export const approveImportVoucher = (id) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${id}/approve`;
    return api.put(url);
}

export const cancelImportVoucher = (id) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${id}/cancel`;
    return api.put(url);
}

export const checkedImportVoucher = (id) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${id}/checked`;
    return api.put(url);
}

export const getImportVoucherLines = (importVoucherId, params) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${importVoucherId}/lines`;
    return api.get(url, { params });
};
export const getImportVoucherLineDetail = importVoucherId => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${importVoucherId}/lines`;
    return api.get(url);
};
export const createImportVoucherLine = (importVoucherId, body) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${importVoucherId}/lines`;
    return api.post(url, body);
};
export const updateImportVoucherLine = (importVoucherId, lineId, body) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${importVoucherId}/lines/${lineId}`;
    return api.put(url, body);
};
export const deleteImportVoucherLine = (importVoucherId, lineId) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY_VOUCHER}/import/${importVoucherId}/lines/${lineId}`;
    return api.delete(url);
};
//
