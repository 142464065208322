import { all, takeEvery, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@iso/services/sync';

//#region CRUD
export function* syncCustomers() {
    yield takeEvery(actions.SYNC_DATA_CUSTOMERS, function* ({ cbSuccess, cbError }) {
        try {
            var response = yield call(services.syncCustomers);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* syncProducts() {
    yield takeEvery(actions.SYNC_DATA_PRODUCTS, function* ({ cbSuccess, cbError }) {
        try {
            var response = yield call(services.syncProducts);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}


export function* syncUsers() {
    yield takeEvery(actions.SYNC_DATA_USERS, function* ({ cbSuccess, cbError }) {
        try {
            var response = yield call(services.syncUsers);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* syncFromSgPharma() {
    yield takeEvery(actions.SYNC_DATA_FROM_SAIGON_PHARMA, function* ({ cbSuccess, cbError }) {
        try {
            var response = yield call(services.syncFromSgPharma);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

//#endregion

export default function* rootSaga() {
    yield all([
        fork(syncCustomers),
        fork(syncProducts),
        fork(syncUsers),
        fork(syncFromSgPharma),
    ])
}