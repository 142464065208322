const actions = {

    GET_BLOGS: 'GET_BLOGS',
    GET_BLOGS_SUCCESS: 'GET_BLOGS_SUCCESS',

    GET_BLOG_DETAIL: 'GET_BLOG_DETAIL',
    GET_BLOG_DETAIL_SUCCESS: 'GET_BLOG_DETAIL_SUCCESS',

    CREATE_BLOG: 'CREATE_BLOG',
    UPDATE_BLOG: 'UPDATE_BLOG',
    DELETE_BLOG: 'DELETE_BLOG',

    BLOG_LOADING_FALSE: 'BLOG_LOADING_FALSE',
    //#region CRUD
    getBlogs: model => ({
        type: actions.GET_BLOGS,
        payload: { model }
    }),
    deleteBlog: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_BLOG,
        payload: { id },
        cbSuccess, cbError
    }),
    getBlogDetail: id => ({
        type: actions.GET_BLOG_DETAIL,
        payload: { id }
    }),
    createBlog: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_BLOG,
        payload: { model },
        cbSuccess, cbError
    }),
    updateBlog: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_BLOG,
        payload: { model },
        cbSuccess, cbError
    }),
    //#endregion
}

export default actions;