import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getProductTypes = body => {
    var url = ApiRouters.PRODUCT_TYPE;
    return api.get(url, { params: body });
};
export const deleteProductType = id => {
    var url = `${ApiRouters.PRODUCT_TYPE}/${id}`;
    return api.delete(url);
};
export const getProductTypeDetail = id => {
    var url = `${ApiRouters.PRODUCT_TYPE}/${id}`;
    return api.get(url);
};
export const createProductType = body => {
    var url = ApiRouters.PRODUCT_TYPE;
    return api.post(url, body);
};
export const updateProductType = body => {
    var url = `${ApiRouters.PRODUCT_TYPE}/${body.id}`;
    return api.put(url, body);
};