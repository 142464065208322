import actions from './actions';

const initState = {
    customers: [],
    customerDetail: {},
    customerTypesTree: [],

    customerTypes: [],
    customerTypeDetail: {},
    visitHistories: [],
    accounts: [],
    loading: false,
    totalItems: 0
};

export default function customerReducer(state = initState, action) {
    switch (action.type) {

        //#region GET LIST TYPE
        case actions.GET_CUSTOMER_TYPES:
            return { ...state, loading: true, totalItems: 0 };
        case actions.GET_CUSTOMER_TYPES_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, customerTypes: res, totalItems, loading: false };
        }
        //#endregion

        //#region DETAIL TYPE
        case actions.GET_CUSTOMER_TYPE_DETAIL:
            return { ...state, loading: true };
        case actions.GET_CUSTOMER_TYPE_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, customerTypeDetail: res, loading: false };
        }
        //#endregion

        //#region GET LIST CUSTOMER
        case actions.GET_CUSTOMERS:
            return { ...state, loading: true };
        case actions.GET_CUSTOMERS_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, customers: res, totalItems, loading: false };
        }
        //#endregion

        //#region DETAIL CUSTOMER
        case actions.GET_CUSTOMER_DETAIL:
            return { ...state, loading: true };
        case actions.GET_CUSTOMER_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, customerDetail: res, loading: false };
        }
        //#endregion

        //#region PURCHASE ORDER
        case actions.CUSTOMER_PURCHASE_ORDER:
            return { ...state, loading: true, totalItems: 0 };
        case actions.CUSTOMER_PURCHASE_ORDER_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, orders: res, totalItems, loading: false };
        }
        //#endregion

        //#region ACCOUNT
        case actions.GET_CUSTOMER_ACCOUNTS:
            return { ...state, loading: true, totalItems: 0 };
        case actions.GET_CUSTOMER_ACCOUNTS_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, accounts: res, totalItems, loading: false };
        }

        //#endregion

        case actions.RESET_CUSTOMER_DETAIL:
            return { ...state, customerDetail: {} };
        case actions.CUSTOMER_LOADING_FALSE:
            return { ...state, loading: false };

        default:
            return state;

    }
}
