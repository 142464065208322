import actions from './actions';

const initState = {
    storeInformations: {},
    loading: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        //#region CRUD
        case actions.GET_SETTING_STORE_INFORMATIONS:
            return { ...state, loading: true, storeInformations: {} };
        case actions.GET_SETTING_STORE_INFORMATIONS_SUCCESS: {
            let { data } = action.payload;
            let storeInformations = data;
            return { ...state, storeInformations, loading: false };
        }

        //#endregion

        case actions.MANUFACTURER_LOADING_FALSE:
            return { ...state, loading: false };

        default:
            return state;

    }
}
