import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@iso/services/report';
import * as moment from "moment";

export function* getReportCommission() {
    yield takeEvery(actions.GET_REPORT_COMMISSION, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload
        try {
            var response = yield call(services.getReportCommission, model);
            if (response.data) {
                const fromDate = moment(new Date(model.fromDate)).format("DD-MM-YYYY");
                const toDate = moment(new Date(model.toDate)).format("DD-MM-YYYY");
                yield cbSuccess();
                const fileName = `Bang ke Doanh so ban hang tu ${fromDate} den ${toDate}.zip`;
                yield exportSuccess(fileName, response.data);
             
            }
        } catch (e) {
            yield put({ type: actions.GET_REPORT_FAIL })
            e.response?.data ? cbError(e.response.data?.error) : cbError();
        }
    })
}

function* exportSuccess(fileName, data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    yield put({ type: actions.GET_REPORT_FAIL });
}

export default function* rootSaga() {
    yield all([
        fork(getReportCommission),
    ])
}