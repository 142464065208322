import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getProducts = body => {
    var url = ApiRouters.PRODUCT;
    if(body.isGetAll) {
        url = `${url}/list`;
    }
    return api.get(url, { params: body });
};

export const deleteProduct = id => {
    var url = `${ApiRouters.PRODUCT}/${id}`;
    return api.delete(url);
};

export const getProductDetail = id => {
    var url = `${ApiRouters.PRODUCT}/${id}`;
    return api.get(url);
};

export const createProduct = body => {
    var url = ApiRouters.PRODUCT;
    return api.post(url, body);
};

export const updateProduct = (id, body) => {
    var url = `${ApiRouters.PRODUCT}/${id}`;
    return api.put(url, body);
};

export const updateShowFirstPage = (productId) => {
    var url = `${ApiRouters.PRODUCT}/${productId}/show-first-page`;
    return api.put(url);
};

export const deleteShowFirstPage = (productId) => {
    var url = `${ApiRouters.PRODUCT}/${productId}/show-first-page`;
    return api.delete(url);
};

export const getProductPriceList = (productId, params) => {
    var url = `${ApiRouters.PRODUCT}/${productId}/price-list`;
    return api.get(url, { params });
};

export const updateProductPriceList = (productId, body) => {
    var url = `${ApiRouters.PRODUCT}/${productId}/price-list`;
    return api.put(url, body);
};