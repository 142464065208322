const actions = {
    GET_INVENTORIES: 'GET_INVENTORIES',
    GET_INVENTORIES_SUCCESS: 'GET_INVENTORIES_SUCCESS',    
    GET_INVENTORIES_BY_EXPIRATION_DATE: 'GET_INVENTORIES_BY_EXPIRATION_DATE',
    GET_INVENTORIES_BY_EXPIRATION_DATE_SUCCESS: 'GET_INVENTORIES_BY_EXPIRATION_DATE_SUCCESS',
    CREATE_INVENTORIES_BY_EXPIRATION_DATE: "CREATE_INVENTORIES_BY_EXPIRATION_DATE",
    UPDATE_INVENTORIES_BY_EXPIRATION_DATE: "UPDATE_INVENTORIES_BY_EXPIRATION_DATE",

    INVENTORY_LOADING_FALSE: 'INVENTORY_LOADING_FALSE',

    //#region CRUD
    getInventories: model => ({
        type: actions.GET_INVENTORIES,
        payload: { model }
    }),

    getInventoriesByExpirationDate: (productId, cbSuccess, cbError) => ({
        type: actions.GET_INVENTORIES_BY_EXPIRATION_DATE,
        payload: { productId },
        cbSuccess, cbError
    }),
   
    createInventoriesByExpirationDate: (productId, body, cbSuccess, cbError) => ({
        type: actions.CREATE_INVENTORIES_BY_EXPIRATION_DATE,
        payload: { productId, body },
        cbSuccess, cbError
    }),

    updateInventoriesByExpirationDate: (productId, inventoryId, body, cbSuccess, cbError) => ({
        type: actions.UPDATE_INVENTORIES_BY_EXPIRATION_DATE,
        payload: { productId, inventoryId, body },
        cbSuccess, cbError
    }),
    //#endregion
}

export default actions;