import language from '@iso/config/language.config';
import vietnameseLang from '@iso/assets/images/flag/vietnam.svg';

const config = {
    defaultLanguage: language,
    options: [
        {
            languageId: 'vietnamese',
            locale: 'vi',
            text: 'Vietnamese',
            icon: vietnameseLang,
        }
    ],
};

export function getCurrentLanguage(lang) {
    let selecetedLanguage = config.options[0];
    config.options.forEach(language => {
        if (language.languageId === lang) {
            selecetedLanguage = language;
        }
    });
    return selecetedLanguage;
}
export default config;
