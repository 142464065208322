import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as commonServices from '@iso/services/common';

//#region VERSION
export function* getVersionApi() {
    yield takeEvery(actions.GET_VERSION_API, function* ({ payload }) {
        try {
            var response = yield call(commonServices.getVersionApi);
            if (response.data.isSuccess === true) {
                var { data } = response.data.data;
                yield put({
                    type: actions.GET_VERSION_API_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.GET_VERSION_API_ERROR, })
        }
    })
}
//#endregion

//#region GROUP
export function* getGroupsCommon() {
    yield takeEvery(actions.GET_GROUPS_COMMON, function* ({ payload }) {
        try {
            var response = yield call(commonServices.getGroupsCommon);
            if (response.data.isSuccess === true) {
                var { data } = response.data.data;
                yield put({
                    type: actions.GET_GROUPS_COMMON_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}
//#endregion

//#region ADDRESS
export function* getProvinces() {
    yield takeEvery(actions.GET_PROVINCE_COMMON, function* ({ payload }) {
        try {
            var response = yield call(commonServices.getProvinces);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_PROVINCE_COMMON_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}
export function* getDistricts() {
    yield takeEvery(actions.GET_DISTRICT_COMMON, function* ({ payload }) {
        const { provinceId } = payload;
        try {
            var response = yield call(commonServices.getDistricts, provinceId);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_DISTRICT_COMMON_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}
export function* getWards() {
    yield takeEvery(actions.GET_WARD_COMMON, function* ({ payload }) {
        const { districtId } = payload;
        try {
            var response = yield call(commonServices.getWards, districtId);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_WARD_COMMON_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

//#endregion

//#region PRICE
export function* getPrices() {
    yield takeEvery(actions.GET_PRICEs_COMMON, function* ({ payload }) {
        try {
            var response = yield call(commonServices.getPrices);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_PRICEs_COMMON_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

export function* getProductTypes() {
    yield takeEvery(actions.GET_PRODUCT_TYPE_COMMON, function* ({ payload }) {
        try {
            var response = yield call(commonServices.getProductTypes);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_PRODUCT_TYPE_COMMON_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

export function* getCalculateUnit() {
    yield takeEvery(actions.GET_CALCULATE_UNIT_COMMON, function* ({ payload }) {
        try {
            var response = yield call(commonServices.getCalculateUnit);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_CALCULATE_UNIT_COMMON_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

export function* getCustomersCommon() {
    yield takeEvery(actions.GET_CUSTOMER_COMMON, function* ({ payload }) {
        try {
            var response = yield call(commonServices.getCustomersCommon);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_CUSTOMER_COMMON_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

export function* getPosCustomerGroups() {
    yield takeEvery(actions.GET_POS_CUSTOMER_GROUPS, function* () {
        try {
            var response = yield call(commonServices.getPosCustomerGroups);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_POS_CUSTOMER_GROUPS_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

export function* getPosEmployees() {
    yield takeEvery(actions.GET_POS_EMPLOYEES, function* () {
        try {
            var response = yield call(commonServices.getPosEmployees);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_POS_EMPLOYEES_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

export function* getViberUsers() {
    yield takeEvery(actions.GET_VIBER_USERS, function* () {
        try {
            var response = yield call(commonServices.getViberUsers);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_VIBER_USERS_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

export function* getZaloUsers() {
    yield takeEvery(actions.GET_ZALO_USERS, function* () {
        try {
            var response = yield call(commonServices.getZaloUsers);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_ZALO_USERS_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

export function* getWarehousesCommon() {
    yield takeEvery(actions.GET_WAREHOUSES_COMMON, function* ({ payload }) {
        const {model} = payload;
        try {
            var response = yield call(commonServices.getWarehousesCommon, model);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_WAREHOUSES_COMMON_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

export function* getSuppliersCommon() {
    yield takeEvery(actions.GET_SUPPLIERS_COMMON, function* ({ payload }) {
        const {model} = payload;
        try {
            var response = yield call(commonServices.getSuppliersCommon, model);
            if (response.data.isSuccess === true) {
                var { items } = response.data.data;
                yield put({
                    type: actions.GET_SUPPLIERS_COMMON_SUCCESS,
                    payload: { data: items }
                })
            }
        } catch (e) {
            yield put({ type: actions.COMMON_RESPONSE_ERROR })
        }
    })
}

//#endregion

export default function* rootSaga() {
    yield all([
        fork(getVersionApi),
        fork(getGroupsCommon),
        fork(getProvinces),
        fork(getDistricts),
        fork(getWards),
        fork(getPrices),
        fork(getProductTypes),
        fork(getCalculateUnit),
        fork(getCustomersCommon),
        fork(getPosCustomerGroups),
        fork(getPosEmployees),
        fork(getViberUsers),
        fork(getZaloUsers),
        fork(getWarehousesCommon),
        fork(getSuppliersCommon),
    ])
}