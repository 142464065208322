import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@iso/services/productTypes';
import * as dummy from './dumy';
import * as _ from 'lodash';

//#region CRUD
export function* getProductTypes() {
    yield takeEvery(actions.GET_PRODUCT_TYPES, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(services.getProductTypes, model);
            if (response.data.isSuccess === true) {
                var data = response.data.data.items;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_PRODUCT_TYPES_SUCCESS,
                    payload: { data, totalItems }
                })
            }

            // var data = dummy.products;
            // var totalItems = dummy.products.length;
            // yield put({
            //     type: actions.GET_PRODUCT_TYPES_SUCCESS,
            //     payload: { data, totalItems }
            // })
        } catch (e) {
            yield put({ type: actions.PRODUCT_TYPE_LOADING_FALSE })
        }
    })
}
export function* deleteProductType() {
    yield takeEvery(actions.DELETE_PRODUCT_TYPE, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(services.deleteProductType, id);
            if (response.data.isSuccess === true)
                yield cbSuccess();
        } catch (e) {
            e.response?.data ? cbError(e.response.data?.error) : cbError();
        }
    })
}
export function* getProductTypeDetail() {
    yield takeEvery(actions.GET_PRODUCT_TYPE_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(services.getProductTypeDetail, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_PRODUCT_TYPE_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.PRODUCT_TYPE_LOADING_FALSE })
        }
    })
}
export function* createProductType() {
    yield takeEvery(actions.CREATE_PRODUCT_TYPE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.createProductType, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* updateProductType() {
    yield takeEvery(actions.UPDATE_PRODUCT_TYPE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.updateProductType, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
//#endregion

export default function* rootSaga() {
    yield all([
        fork(getProductTypes),
        fork(deleteProductType),
        fork(getProductTypeDetail),
        fork(createProductType),
        fork(updateProductType),
    ])
}