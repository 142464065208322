import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@iso/services/blog';
import * as dummy from './dumy';
import * as _ from 'lodash';

//#region CRUD
export function* getBlogs() {
    yield takeEvery(actions.GET_BLOGS, function* ({ payload }) {
        const { model } = payload;
        try {
            // var response = yield call(services.getBlogs, model);
            // if (response.data.isSuccess === true) {
            //     var data = response.data.data.data;
            //     var totalItems = response.data.data.total;
            //     yield put({
            //         type: actions.GET_BLOGS_SUCCESS,
            //         payload: { data, totalItems }
            //     })
            // }

            var data = dummy.products;
            var totalItems = dummy.products.length;
            yield put({
                type: actions.GET_BLOGS_SUCCESS,
                payload: { data, totalItems }
            })
        } catch (e) {
            yield put({ type: actions.BLOG_LOADING_FALSE })
        }
    })
}
export function* deleteBlog() {
    yield takeEvery(actions.DELETE_BLOG, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            // var response = yield call(services.deleteBlog, id);
            // if (response.data.isSuccess === true)
            //     yield cbSuccess();
            _.remove(dummy.products, { id: id });
            yield cbSuccess();

        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* getBlogDetail() {
    yield takeEvery(actions.GET_BLOG_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(services.getBlogDetail, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_BLOG_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.BLOG_LOADING_FALSE })
        }
    })
}
export function* createBlog() {
    yield takeEvery(actions.CREATE_BLOG, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.createBlog, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
export function* updateBlog() {
    yield takeEvery(actions.UPDATE_BLOG, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.updateBlog, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
//#endregion

export default function* rootSaga() {
    yield all([
        fork(getBlogs),
        fork(deleteBlog),
        fork(getBlogDetail),
        fork(createBlog),
        fork(updateBlog),
    ])
}