const actions = {

    GET_ORDERS: 'GET_ORDERS',
    GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',

    GET_ORDER_DETAIL: 'GET_ORDER_DETAIL',
    GET_ORDER_DETAIL_SUCCESS: 'GET_ORDER_DETAIL_SUCCESS',

    CREATE_ORDER: 'CREATE_ORDER',
    UPDATE_ORDER: 'UPDATE_ORDER',
    DELETE_ORDER: 'DELETE_ORDER',

    GET_ORDER_STATES: 'GET_ORDER_STATES',
    GET_ORDER_STATES_SUCCESS: 'GET_ORDER_STATES_SUCCESS',

    GET_NEW_ORDERS: 'GET_NEW_ORDERS',
    GET_NEW_ORDERS_SUCCESS: 'GET_NEW_ORDERS_SUCCESS',

    MERGE_NEW_ORDERS: 'MERGE_NEW_ORDERS',
    MERGE_NEW_ORDERS_SUCCESS: 'MERGE_NEW_ORDERS_SUCCESS',

    CHANGE_STATUS: 'CHANGE_STATUS',
    ORDER_LOADING_FALSE: 'ORDER_LOADING_FALSE',

    //#region CRUD
    getOrders: model => ({
        type: actions.GET_ORDERS,
        payload: { model }
    }),
    deleteOrder: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_ORDER,
        payload: { id },
        cbSuccess, cbError
    }),
    getOrderDetail: id => ({
        type: actions.GET_ORDER_DETAIL,
        payload: { id }
    }),
    createOrder: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_ORDER,
        payload: { model },
        cbSuccess, cbError
    }),
    updateOrder: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_ORDER,
        payload: { model },
        cbSuccess, cbError
    }),
    changeStatus: (model, cbSuccess, cbError) => ({
        type: actions.CHANGE_STATUS,
        payload: { model },
        cbSuccess, cbError
    }),
    getOrderStates: id => ({
        type: actions.GET_ORDER_STATES,
        payload: { id }
    }),

    getNewOrders: id => ({
        type: actions.GET_NEW_ORDERS,
        payload: { id }
    }),

    mergeNewOrders: (model, cbSuccess, cbError) => ({
        type: actions.MERGE_NEW_ORDERS,
        payload: { model },
        cbSuccess, cbError
    }),

    updateOrderCompletedDate: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_ORDER_COMPLETED_DATE,
        payload: { model },
        cbSuccess, cbError
    }),
    
    //#endregion

    //#region ORDER LINE

    GET_ORDER_LINES: 'GET_ORDER_LINES',
    GET_ORDER_LINES_SUCCESS: 'GET_ORDER_LINES_SUCCESS',

    GET_ORDER_LINE_DETAIL: 'GET_ORDER_LINE_DETAIL',
    GET_ORDER_LINE_DETAIL_SUCCESS: 'GET_ORDER_LINE_DETAIL_SUCCESS',

    CREATE_ORDER_LINE: 'CREATE_ORDER_LINE',
    UPDATE_ORDER_LINE: 'UPDATE_ORDER_LINE',
    DELETE_ORDER_LINE: 'DELETE_ORDER_LINE',
    UPDATE_ORDER_COMPLETED_DATE: 'UPDATE_ORDER_COMPLETED_DATE',

    getOrderLines: (model, orderId) => ({
        type: actions.GET_ORDER_LINES,
        payload: { model, orderId }
    }),
    deleteOrderLine: (model, cbSuccess, cbError) => ({
        type: actions.DELETE_ORDER_LINE,
        payload: { model },
        cbSuccess, cbError
    }),
    getOrderLineDetail: id => ({
        type: actions.GET_ORDER_LINE_DETAIL,
        payload: { id }
    }),
    createOrderLine: (model, orderId, cbSuccess, cbError) => ({
        type: actions.CREATE_ORDER_LINE,
        payload: { model, orderId },
        cbSuccess, cbError
    }),
    updateOrderLine: (model, orderId, cbSuccess, cbError) => ({
        type: actions.UPDATE_ORDER_LINE,
        payload: { model, orderId },
        cbSuccess, cbError
    }),
    
    //#endregion
}

export default actions;