import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getStoreInformations = () => {
    var url = `${ApiRouters.SETTING}/store-informations`;
    return api.get(url);
};

export const updateStoreInformations = (body) => {
    var url = `${ApiRouters.SETTING}/store-informations`;
    return api.put(url, body);
};
