import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getBlogs = body => {
    var url = ApiRouters.BLOG;
    return api.get(url, { params: body });
};
export const deleteBlog = id => {
    var url = `${ApiRouters.BLOG}/${id}`;
    return api.delete(url);
};
export const getBlogDetail = id => {
    var url = `${ApiRouters.BLOG}/${id}`;
    return api.get(url);
};
export const createBlog = body => {
    var url = ApiRouters.BLOG;
    return api.post(url, body);
};
export const updateBlog = body => {
    var url = `${ApiRouters.BLOG}/${body.id}`;
    return api.put(url, body);
};