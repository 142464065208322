import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@iso/services/product';
import * as _ from 'lodash';

//#region CRUD
export function* getProducts() {
    yield takeEvery(actions.GET_PRODUCTS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(services.getProducts, model);
            if (response.data.isSuccess === true) {
                var data = response.data.data.items;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_PRODUCTS_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.PRODUCT_LOADING_FALSE })
        }
    })
}

export function* deleteProduct() {
    yield takeEvery(actions.DELETE_PRODUCT, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(services.deleteProduct, id);
            if (response.data.isSuccess === true)
                yield cbSuccess();
        } catch (e) {
            e.response?.data ? cbError(e.response.data?.error) : cbError();
        }
    })
}

export function* getProductDetail() {
    yield takeEvery(actions.GET_PRODUCT_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(services.getProductDetail, id);
            if (response.data.isSuccess === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_PRODUCT_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.PRODUCT_LOADING_FALSE })
        }
    })
}

export function* createProduct() {
    yield takeEvery(actions.CREATE_PRODUCT, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(services.createProduct, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* updateProduct() {
    yield takeEvery(actions.UPDATE_PRODUCT, function* ({ payload, cbSuccess, cbError }) {
        const { id, model } = payload;
        try {
            var response = yield call(services.updateProduct, id, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* updateProductShowFirstPage() {
    yield takeEvery(actions.UPDATE_PRODUCT_SHOW_FIRST_PAGE, function* ({ payload, cbSuccess, cbError }) {
        const { productId } = payload;
        try {
            var response = yield call(services.updateShowFirstPage, productId);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export function* deleteProductShowFirstPage() {
    yield takeEvery(actions.DELETE_PRODUCT_SHOW_FIRST_PAGE, function* ({ payload, cbSuccess, cbError }) {
        const { productId } = payload;
        try {
            var response = yield call(services.deleteShowFirstPage, productId);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}
//#endregion

export function* getProductPriceList() {
    yield takeEvery(actions.GET_PRODUCT_PRICE_LIST, function* ({ payload }) {
        const { productId, model } = payload;
        try {
            var response = yield call(services.getProductPriceList, productId, model);
            if (response.data.isSuccess === true) {
                var data = response.data.data.items;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_PRODUCT_PRICE_LIST_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.PRODUCT_LOADING_FALSE })
        }
    })
}

export function* updateProductPriceList() {
    yield takeEvery(actions.UPDATE_PRODUCT_PRICE_LIST, function* ({ payload, cbSuccess, cbError }) {
        const { productId, model } = payload;
        try {
            var response = yield call(services.updateProductPriceList, productId, model);
            if (response.data.isSuccess === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data?.error);
            else
                yield cbError();
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getProducts),
        fork(deleteProduct),
        fork(getProductDetail),
        fork(createProduct),
        fork(updateProduct),
        fork(updateProductShowFirstPage),
        fork(deleteProductShowFirstPage),
        fork(getProductPriceList),
        fork(updateProductPriceList),
    ])
}