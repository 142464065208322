import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

//#region PACKAGE_PRODUCT
export const getList = (params) => {
    var url = ApiRouters.WAREHOUSES.INVENTORY;
    return api.get(url, { params });
};

export const getInventoriesByExpirationDate = (productId) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY}/${productId}/by-expiration-date`;
    return api.get(url);
};

export const createInventoriesByExpirationDate = (productId, body) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY}/${productId}/by-expiration-date`;
    return api.post(url, body);
};

export const updateInventoriesByExpirationDate = (productId, inventoryId, body) => {
    var url = `${ApiRouters.WAREHOUSES.INVENTORY}/${productId}/by-expiration-date/${inventoryId}`;
    return api.put(url, body);
};


