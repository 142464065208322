import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

//#region ORDER
export const getOrders = body => {
    var url = ApiRouters.ORDER;
    return api.get(url, { params: body });
};
export const deleteOrder = id => {
    var url = `${ApiRouters.ORDER}/${id}`;
    return api.delete(url);
};
export const getOrderDetail = id => {
    var url = `${ApiRouters.ORDER}/${id}`;
    return api.get(url);
};
export const createOrder = body => {
    var url = ApiRouters.ORDER;
    return api.post(url, body);
};
export const updateOrder = body => {
    var url = `${ApiRouters.ORDER}/${body.id}`;
    return api.put(url, body);
};
export const changeStatus = body => {
    var url = `${ApiRouters.ORDER}/${body.orderId}/status`;
    return api.put(url, body);
};
export const getOrderStates = id => {
    var url = `${ApiRouters.ORDER}/${id}/order-states`;
    return api.get(url);
};

export const getNewOrders = id => {
    var url = `${ApiRouters.ORDER}/${id}/new-orders`;
    return api.get(url);
};

export const mergeNewOrders = (body) => {
    var url = `${ApiRouters.ORDER}/${body.id}/merge-new-orders`;
    return api.put(url, body);
};

export const updateOrderCompletedDate = (body) => {
    var url = `${ApiRouters.ORDER}/${body.id}/completed-date`;
    return api.put(url, body);
}
//#endregion

//#region ORDER LINE
export const getOrderLines = (body, orderId) => {
    var url = `${ApiRouters.ORDER}/${orderId}/order-lines`;
    return api.get(url, { params: body });
};
export const deleteOrderLine = body => {
    var url = `${ApiRouters.ORDER}/${body.orderId}/order-lines/${body.productId}`;
    return api.delete(url);
};
export const getOrderLineDetail = id => {
    var url = `${ApiRouters.ORDER}/${id}`;
    var url = `${ApiRouters.ORDER}/${orderId}/order-lines`;
    return api.get(url);
};
export const createOrderLine = (body, orderId) => {
    var url = `${ApiRouters.ORDER}/${orderId}/order-lines`;
    return api.post(url, body);
};
export const updateOrderLine = (body, orderId) => {
    var url = `${ApiRouters.ORDER}/${orderId}/order-lines/${body.orderLineId}`;
    return api.put(url, body);
};
//#endregion